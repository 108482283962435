$(document).ready(function () {

    $(".form-container").css('display', 'flex');
    $(".form-submitted").css('display', 'none');
    $("#form").css('background', '#F9F5EF');
    $("body:not(:first-child)").addClass("hide");

    $(".blue-circle").css("display", "none");

    $(".full-name-text p").css("color", "rgb(20, 58, 87)");
    $(".phone-text p").css("color", "rgb(20, 58, 87)");
    $(".email-text p").css("color", "rgb(20, 58, 87)");
    

    $('.full-name-field input').on('input', function (e) {
        var fullName = $('.full-name-field input').val();
        if (fullName != "") {
            $(".name-circle").css("display", "none");
            $(".name-blue-circle").css("display", "block");
            $(".full-name-text p").css('color', '#49BAF1');
        } else {
            $(".name-circle").css("display", "block");
            $(".name-blue-circle").css("display", "none");
            $(".full-name-text p").css('color', '#143A57');
        }
    });

    $('.phone-field input').on('input', function (e) {
        var phoneNumber = $('.phone-field input').val();
        var isValid = phonenumber(phoneNumber)
        if (isValid) {
            $(".phone-circle").css("display", "none");
            $(".phone-blue-circle").css("display", "block");
            $(".phone-text p").css('color', '#49BAF1');
        } else {
            $(".phone-circle").css("display", "block");
            $(".phone-blue-circle").css("display", "none");
            $(".phone-text p").css('color', '#143A57');
        }
    });

    function phonenumber(inputtxt) {
        var phoneno = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
        if(inputtxt.match(phoneno)) {
          return true;
        }
        else {
          return false;
        }
      }

    $('.email-field input').on('input', function (e) {
        var email = $('.email-field input').val();
        var isValid = ValidateEmail(email)
        if (isValid) {
            $(".email-circle").css("display", "none");
            $(".email-blue-circle").css("display", "block");
            $(".email-text p").css('color', '#49BAF1');
        } else {
            $(".email-circle").css("display", "block");
            $(".email-blue-circle").css("display", "none");
            $(".email-text p").css('color', '#143A57');
        }
    });

    function ValidateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return (true)
        }
        return (false)
    }

    $(".submit-btn button").on("click", function() {


        var fullName = $('.full-name-field input').val();
        var phoneNumber = $('.phone-field input').val();
        var phoneValid = phonenumber(phoneNumber)
        var email = $('.email-field input').val();
        var message = $('.message-field').val();
        var emailValid = ValidateEmail(email)

        var errors = ""
        if (fullName === "") {
            errors += "\n Full name was not filled!"
            $(".name-circle").css("display", "block");
            $(".name-blue-circle").css("display", "none");
            $(".full-name-text p").css("color", "red");
        }
        if (phoneNumber === "") {
            errors += "\n Phone number was not filled!"
            $(".phone-circle").css("display", "block");
            $(".phone-blue-circle").css("display", "none");
            $(".phone-text p").css("color", "red");
        }
        else if (!phoneValid) {
            errors += "\n Phone number is incorect!"
            $(".phone-circle").css("display", "block");
            $(".phone-blue-circle").css("display", "none");
            $(".phone-text p").css("color", "red");
        }
        if (email === "") {
            errors += "\n Email was not filled!"
            $(".email-circle").css("display", "block");
            $(".email-blue-circle").css("display", "none");
            $(".email-text p").css("color", "red");
        }
        else if (!emailValid) {
            errors += "\n Email is incorect!"
            $(".email-circle").css("display", "block");
            $(".email-blue-circle").css("display", "none");
            $(".email-text p").css("color", "red");
        }

        if(errors != "") {
            //alert( errors );
        }
        else {


            const url='https://classicimage.com/SendEmail.php';
            const data={
                name:fullName,
                body: "<div> <h3>Full name: " + fullName + "</h3></br><h3>Phone: " + phoneNumber + "</h3></br><h3>Email: " + email + "</h3></br><h3>Message: " + message + "</h3></div>"
            };

            $(".form-container").css('display', 'none');
            $(".form-submitted").css('display', 'flex');
            $("#form").css('background', '#F1FAEC');

            $.post(url, data, function(data, status) {
                console.log(`${data} and status is ${status}`)
                

            });

        }

      });

      
    $(".close").on("click", function() {
        //window.location.replace("index.html");
        $("html").css("overflow-y", "unset");
        $("#form").css("display", "none");
    });

});




